import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";

import { Title } from "../../components/Title.jsx";
import "../../stylesheets/Shipments.scss";

import {
  ShipmentsTableSearch,
  LoadingTable,
} from "../../components/table/shipments/ShipmentTable";

import { Box } from "../../components/home/Box.jsx";
import { toast } from "react-toastify";

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

const Shipments = () => {
  const cookies = new Cookies();
  const token = cookies.get("token_user");
  const URL = process.env.REACT_APP_API_URL + "/user";

  const initial_url = `${URL}/shipments`;

  const [shipments, setShipments] = useState();
  const [curShipments, setCurShipments] = useState(shipments);
  const [searchParams, setSearchParams] = useSearchParams();
  const [toUpdate, setToUpdate] = useState(false);

  const [shipmentsUrl, setShipmentsUrl] = useState(initial_url);

  const getShipments = async () => {
    try {
      const response = await fetchWithTimeout(shipmentsUrl, {
        timeout: 8000,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      if (response.status == 200) {
        let data = await response.json();
        setShipments(data);

        const myFilter = searchParams.get("filter");
        if (myFilter) {
          var results = [];
          switch (myFilter) {
            case "documents-required":
              results = data.result.filter(
                (i) => i.status === "PENDING DOCUMENTS"
              );
              break;
            case "under-cbp-exam":
              results = data.result.filter((i) => {
                if (
                  i.status === "CBP INTENSIVE EXAM" ||
                  i.status === "CBP VACIS EXAM" ||
                  i.status === "CBP DOCS REVIEW HOLD" ||
                  i.status === "ISF HOLD"
                ) {
                  return true;
                }
              });
              break;
            case "under-fda-exam":
              results = data.result.filter((i) => {
                if (i.fda_review != null && i.fda_review != "MAY PROCEED") {
                  return true;
                }
              });
              break;

            case "7501-review":
              results = data.result.filter((i) => {
                if (i?.["7501_to_review"] === true) {
                  return i;
                }
              });
              break;
              case "without-do":
                results = data.result.filter(
                  (i) => i.status !== "SUBMITTED" && !i.delivery_order_exists
                );
                break;                           
            default:
              results = data.result;
              break;
          }
          data["result"] = results;
        }
        setCurShipments(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShipments();
  }, [toUpdate]);

  useEffect(() => {
    getShipments();
  }, [shipmentsUrl]);

  const changeFilter = (e) => {
    if (e) {
      let url = `${initial_url}?status=${e}`;
      setShipmentsUrl(url);
    } else {
      setShipmentsUrl(initial_url);
    }
  };

  return (
    <>
      <Title
        title="Shipments"
        links={[
          {
            link: "/",
            title: "Home",
          },
          {
            link: "/shipments",
            title: "Shipments",
          },
        ]}
      />
      <div id="shipments" className="page-content">
        <div className="data-table">
          {curShipments ? (
            <ShipmentsTableSearch
              shipments={curShipments}
              onFilterChange={changeFilter}
            />
          ) : (
            <LoadingTable />
          )}
        </div>
      </div>
    </>
  );
};

export default Shipments;
