import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

import Notification from "../../components/Notification.jsx";
import { Title } from "../../components/Title.jsx";
import { Box, LoadingBox } from "../../components/home/Box.jsx";
import {
  ShipmentsTable,
  LoadingTable,
  ShipmentsTableSearch,
} from "../../components/table/shipments/ShipmentTable.jsx";

//---------------------------STYLES------------------------------
import "../../App.scss";
import "../../stylesheets/Home.scss";
import GlobalAlert from "../../components/GlobalAlert.jsx";

const Home = () => {
  const cookies = new Cookies();
  const name = cookies.get("name_user");
  const token = cookies.get("token_user");
  const navigate = useNavigate();

  if (!token) {
    navigate("/login");
  }

  const URL = process.env.REACT_APP_API_URL + "/user";
  const initial_url = `${URL}/shipments`;

  const [loading, setLoading] = useState({
    shipments: true,
    needUploads: true,
    beforeDate: true,
    invoices: false,
    exam: true,
    withoutDO: true, // Added new state for loading shipments without DO
  });
  const [shipmentsNeeding, setShipmentsNeeding] = useState();
  const [globalNotification, setGlobalNotification] = useState([]);
  const [shipmentsBeforeDate, setShipmentsBeforeDate] = useState(0);
  const [shipmentsExam, setShipmentsExam] = useState(0);
  const [shipments7501, setShipments7501] = useState(0);
  const [shipmentsFDAExam, setShipmentsFDAExam] = useState(0);
  const [shipmentsWithoutDO, setShipmentsWithoutDO] = useState(0); // Added new state for shipments without DO
  const [shipments, setShipments] = useState();
  const [shipmentsUrl, setShipmentsUrl] = useState(initial_url);

  const getNotification = async () => {
    fetch(`${URL}/get_notification`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.length > 0) {
          setGlobalNotification((oldArray) => [...oldArray, data[0]]);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleNotificationDismiss = () => {
    setGlobalNotification([]);
  };

  const getShipments = async () => {
    let curLoading = loading;
    try {
      const response = await fetch(shipmentsUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      if (response.status == 200) {
        let data = await response.json();

        data.result = data?.result?.filter(
          (item) =>
            !(
              item?.status === "SUBMITTED" &&
              (item?.fda_review === null || item?.fda_review === "MAY PROCEED")
            ) &&
            item?.status !== "NOT CLEARED BY AFB" &&
            !(
              item?.status === "RELEASED" &&
              (item?.fda_review === null || item?.fda_review === "MAY PROCEED")
            )
        );

        setShipmentsNeeding(data?.pending_docs);
        if (data) setShipments7501(data["7501_to_review"]);
        setShipmentsBeforeDate(data?.before_summary);
        setShipmentsExam(data?.under_exam);
        setShipmentsFDAExam(data?.fda_exam);
        setShipmentsWithoutDO(data?.shipments_without_do); // Set the new state for shipments without DO
        loading.shipments = false;
        loading.beforeDate = false;
        loading.exam = false;
        loading.needUploads = false;
        loading.withoutDO = false; // Update loading state for shipments without DO
        setLoading(curLoading);
        setShipments(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setGreeting = (user_name) => {
    // String variable initialization
    let result_string;
    // Date Variables
    const current_date = new Date();
    const hours = current_date.getHours();
    const minutes = current_date.getMinutes();
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    // Switch statement to create result string
    switch (true) {
      case formattedHours >= 0 && formattedHours < 12:
        result_string = `Good morning, ${user_name ? user_name : "User"}`;
        break;
      case formattedHours >= 12 && formattedHours < 18:
        result_string = `Good afternoon, ${user_name ? user_name : "User"}`;
        break;
      case formattedHours >= 18 && formattedHours <= 23:
        result_string = `Good evening, ${user_name ? user_name : "User"}`;
        break;
      default:
        result_string = `Good day, ${user_name ? user_name : "User"}`;
    }

    // Return of result string to be used by component
    return result_string;
  };

  useEffect(() => {
    getNotification();
    getShipments();
  }, []);

  useEffect(() => {
    getShipments();
  }, [shipmentsUrl]);

  const changeFilter = (e) => {
    if (e) {
      let url = `${initial_url}?status=${e}`;
      setShipmentsUrl(url);
    } else {
      setShipmentsUrl(initial_url);
    }
  };

  return (
    <>
      <div className="home">
        <Title title={setGreeting(name)} />
      </div>
      <div id="home" className="page-content">
        <GlobalAlert
          notification={globalNotification}
          onDismiss={handleNotificationDismiss}
        />
        <div className="boxes">
          {loading.needUploads ? (
            <LoadingBox />
          ) : (
            <Box
              number={shipmentsNeeding}
              text="Documents Required"
              link="shipments?filter=documents-required"
            />
          )}
          {loading.withoutDO ? (
            <LoadingBox />
          ) : (
            <Box
              number={shipmentsWithoutDO}
              text="Without Delivery Order"
              link="shipments?filter=without-do"
            />
          )}
          {loading.exam ? (
            <LoadingBox />
          ) : (
            <Box
              number={shipments7501}
              text="7501 Forms to Review"
              link="shipments?filter=7501-review"
            />
          )}
          {loading.exam ? (
            <LoadingBox />
          ) : (
            <Box
              number={shipmentsExam}
              text="CBP Hold/Exam"
              link="shipments?filter=under-cbp-exam"
            />
          )}
          {loading.exam ? (
            <LoadingBox />
          ) : (
            <Box
              number={shipmentsFDAExam}
              text="FDA Hold/Exam"
              link="shipments?filter=under-fda-exam"
            />
          )}

        </div>
        <div className="data-table">
          {loading.shipments ? (
            <LoadingTable />
          ) : (
            <ShipmentsTableSearch
              shipments={shipments}
              onFilterChange={changeFilter}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default Home;
