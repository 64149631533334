import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { format } from "date-fns";

import Loading from "../../../components/Loading";

import "../tables.scss";

//Function that recieves the number of extra containers
const fewContainers = (containers) => {
  var formatted = "";
  for (let i = 0; i < containers?.length; i++) {
    formatted += containers[i] + ", "
  }

  return (
    <span>
      {formatted.slice(0, formatted?.length - 2)}
    </span>
  );
}

const ManyContainers = ({ containers }) => {
  var firstShipments = "";
  var otherShipments = "";
  var count = 0;
  var [opened, setOpened] = useState(false);

  if (!(typeof containers === 'undefined' || containers?.length <= 0)) {
    for (let i = 0; i <= 1; i++) {
      //First three shipments
      firstShipments += containers[i] + ", ";
    }
    for (let i = 2; i < containers.length; i++) {
      otherShipments += containers[i] + ", ";
      count += 1;
    }
  }

  //Removing last ","
  return (
    <span onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
      {firstShipments.slice(0, firstShipments?.length - 2)},
      {opened ? otherShipments.slice(0, otherShipments?.length - 2) : ""}
      {!opened ?
        <span className="container-extensor">+{count}</span> :
        <span className="container-extensor" style={{ marginRight: "-1rem" }}>close</span>
      }
    </span>
  );
}

const COLUMNS = [
  {
    Header: "Invoice",
    accessor: "proper_name",
    Cell: ({ cell }) => {
      const obj = cell?.row?.original;

      let name = obj?.proper_name;
      let lastTwo = `${name[name.length - 2]}${name[name.length - 1]}`;
      let formattedName = "";

      if (lastTwo !== "BI") {
        formattedName = `${obj.file_number}-${name[name.length - 1]}`
      } else {
        formattedName = obj.file_number
      }

      return (
        <>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
              maxWidth: "250px"
            }}
          >
            {formattedName}
          </p>
        </>
      )
    },
  },
  {
    Header: "Master Bill of Lading",
    accessor: "master_bill_lading",
  },
  {
    Header: "Container Number(s)",
    accessor: "containers",
    Cell: ({ value }) => (
      <>
        <p style={{ fontSize: "12px", width: "22rem", margin: "auto" }}>
          {value?.length > 0 ?
            (value?.length <= 2 ?
              fewContainers(value) :
              <ManyContainers containers={value} />
            )
            :
            <span>
              No containers info provided.
            </span>
          }
        </p>
      </>
    )
  },
  {
    Header: "Reference Number",
    accessor: "customer_reference_no",
    Cell: ({ value }) => (
      <>
        <p className="f-12 m-auto">

          {value?.length > 0 ?
            value
            : null
          }
        </p>
      </>
    )
  },
  {
    Header: "Created",
    accessor: "created_at",
    Cell: ({ value }) => {
      if (value != "0000-00-00 00:00:00") {
        return format(new Date(value), "MM/dd/yyyy");
      } else {
        return "";
      }
    },
    id: "date",
    filter: "dateBetween"
  }
];

export const BillingTable = ({ invoices }) => {

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => invoices, [invoices]);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: data.hasOwnProperty('result') ? data?.result : data,
  }, useSortBy);

  return (
    <>
      {!data ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <h1
            style={{
              marginBottom: "12.5px",
              fontSize: "1.3rem",
              color: "#303030",
            }}
          >
            Invoices
          </h1>
          <div className="table-wrapper">
            <table {...getTableBodyProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ textAlign: "center" }}
                  >
                    {headerGroup.headers.map((column) => {
                      let align = "center";
                      if (column.Header == "Invoice") {
                        align = "left";
                      }
                      return (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ textAlign: align, cursor: "pointer" }}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <svg xmlns="http://www.w3.org/2000/svg" width="10.317" height="7.317" viewBox="0 0 10.317 7.317">
                                  <g transform="translate(-188.092 -344.092)">
                                    <line x2="3.75" y2="4.5" transform="translate(189.5 345.5)" fill="none" stroke="#303030" strokeLinecap="round" strokeWidth="2" />
                                    <line x1="3.75" y2="4.5" transform="translate(193.25 345.5)" fill="none" stroke="#303030" strokeLinecap="round" strokeWidth="2" />
                                  </g>
                                </svg>

                                : <svg xmlns="http://www.w3.org/2000/svg" width="10.317" height="7.317" viewBox="0 0 10.317 7.317">
                                  <g transform="translate(1.408 1.408)">
                                    <line y1="4.5" x2="3.75" fill="none" stroke="#303030" strokeLinecap="round" strokeWidth="2" />
                                    <line x1="3.75" y1="4.5" transform="translate(3.75)" fill="none" stroke="#303030" strokeLinecap="round" stroke-width="2" />
                                  </g>
                                </svg>
                              : ''}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} style={{ textAlign: "center" }}>
                      {row.cells.map((cell) => {
                        let align = "center";
                        let isFile = false;
                        if (cell.column.Header == "Invoice") {
                          align = "left";
                          isFile = true;
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              textAlign: align
                            }}
                          >
                            {isFile ? <NavLink to={`/billing/${cell?.row?.original.id}`}>{cell.render("Cell")}</NavLink> : cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default BillingTable;
